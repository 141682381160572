<template>
  <div class="created-by">
    <a href="https://www.divtec.ch/ecole-des-metiers-techniques/">
      Application développée par <br>
      <strong>L'École des Métiers Techniques de Porrentruy</strong>
    </a>
    <br>
    <a href="https://www.salon-formation.ch/">
      en collaboration avec le<br>
      <strong>Comité du Salon interjurassien de la formation</strong>
    </a>

  </div>
</template>

<script>
export default {
  name: 'CreatedBy'
}
</script>

<style lang="scss" scoped>
  .created-by {
    padding: 20px;
    text-align: center;

    a {
      font-size: .9rem;
      line-height: 1.5;
      color: var(--ion-color-medium);
      text-decoration: none;
    }
  }
</style>
