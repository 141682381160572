<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title size="large">Qualités</ion-title>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar
          placeholder=""
          debounce="0"
          @ionInput="recherche = $event.target.value"
        ></ion-searchbar>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ListeQualites
        :titre="'Des métiers pour ' +
         '<strong style=\'color:var(--ion-color-primary); font-size: 1rem\'>' +
          'tes qualités</strong>'"
        :qualites="qualitesTrouves"
      />

      <created-by />
    </ion-content>
  </ion-page>
</template>
<script>

import ListeQualites from '../components/ListeQualites'
import CreatedBy from '@/components/CreatedBy'
import {
  IonContent,
  IonHeader,
  IonPage,
  IonSearchbar, IonTitle,
  IonToolbar
} from "@ionic/vue";
import {mapGetters} from "vuex";
export default {
  name: 'PageQualites',
  components: {
    CreatedBy,
    ListeQualites,
    IonPage,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonSearchbar,
    IonContent
  },
  data () {
    return {
      recherche: ''
    }
  },
  computed: {
    ...mapGetters(['qualites']),
    qualitesTrouves () {
      if (this.recherche) {
        return this.qualites.filter((qualite) => {
          return qualite.libelle.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
              .includes(this.recherche.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
        })
      } else {
        return this.qualites
      }
    }
  },
}
</script>
